<template>
  <div>
    <LazyHeader />
    <LazyBreadCrumbs class="customContainer" />
    <section class="customContainer px-4">
      <div class="flex flex-col gap-6 lg:gap-0">
        <h1
          v-if="isShowTitle"
          class="text-2xl font-bold text-black lg:pb-10 lg:text-[40px]"
        >
          {{ pageStore.currentPage.title }}
        </h1>
        <div class="flex flex-row xl:gap-[20px]">
          <div class="h-[490px] max-xl:hidden lg:w-[60%] 3xl:w-[40%] 5xl:w-[440px]">
            <LazyLoanCalculator class="border-2 border-dnd_light_grey" />
          </div>
          <slot />
        </div>
      </div>
      <LazyCardMotivation class="mt-6 lg:mb-20 lg:mt-[120px]" />
    </section>
    <Chat v-if="chatStore.isChatAvailable" />
    <LazyFooter />
  </div>
</template>

<script setup>
const { $ws } = useNuxtApp();
const route = useRoute();
const pageStore = usePagesStore();
const chatStore = useChatStore();

const isShowTitle = ref(true);

const updateShowTitle = (path) => {
  const slashCount = path.split('/').length - 1;
  isShowTitle.value = slashCount <= 2;
};

watch(
  () => route.fullPath,
  (newPath) => {
    const slashCount = newPath.split('/').length - 1;
    if (slashCount > 2) {
      isShowTitle.value = false;
    } else {
      isShowTitle.value = true;
    }
  },
);

onBeforeMount(() => {
  updateShowTitle(route.fullPath);
});

onMounted(async () => {
  if (!$ws.sockets.chat) {
    await $ws.useConnection();
  }
});
</script>
